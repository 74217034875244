<template>
  <div class="vipCenter">
    <div class="content">
      <div class="aside">
        <!-- 面包屑 -->
        <div class="bread">
          <h5>当前位置：</h5>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }"
              >汇成国际</el-breadcrumb-item
            >
            <el-breadcrumb-item>会员中心</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="tabbar">
          <!-- 用户 -->
          <div class="user">
            <div class="user-head">
              <img :src="net + pic" alt="" />
            </div>
            <div class="user-info">
              <div class="info-top">
                <span>{{ user.name }}</span>
                <img
                  v-if="!user.signIn"
                  src="../../assets/img/qiandao.png"
                  alt=""
                  @click="
                    signInShow = true;
                    signIn();
                  "
                />
                <img
                  v-else
                  src="../../assets/img/yiqiandao.png"
                  alt=""
                  @click="
                    signInShow = true;
                    signIn();
                  "
                />
              </div>
              <div class="info-bottom">
                余额:<span>{{ (user.balance / 100).toFixed(2) }}</span>
              </div>
            </div>
          </div>
          <div class="tabbars">
            <div
              class="tabbar-item"
              @click="$router.push('/vipCenter/profile')"
            >
              <img src="../../assets/img/huiyuan.png" alt="" />
              <span
                :style="
                  $route.path == '/vipCenter/profile' ? 'color: #207EFF;' : ''
                "
                >会员中心</span
              >
            </div>
            <div class="tabbar-item" @click="$router.push('/vipCenter/myJoin')">
              <img src="../../assets/img/ruhuo.png" alt="" />
              <span
                :style="
                  $route.path == '/vipCenter/myJoin' ? 'color: #207EFF;' : ''
                "
                >我的入伙</span
              >
            </div>
            <div
              class="tabbar-item"
              @click="$router.push('/vipCenter/myCustom')"
            >
              <img src="../../assets/img/dingzhi.png" alt="" />
              <span
                :style="
                  $route.path == '/vipCenter/myCustom' ? 'color: #207EFF;' : ''
                "
                >我的定制</span
              >
            </div>
            <div
              class="tabbar-item"
              @click="$router.push('/vipCenter/recharge')"
            >
              <img src="../../assets/img/chongzhi.png" alt="" />
              <span
                :style="
                  $route.path.indexOf('/vipCenter/recharge') !== -1
                    ? 'color: #207EFF;'
                    : ''
                "
                >我要充值</span
              >
            </div>
            <div
              class="tabbar-item"
              @click="$router.push('/vipCenter/withdraw')"
            >
              <img src="../../assets/img/qukuan.png" alt="" />
              <span
                :style="
                  $route.path.indexOf('/vipCenter/withdraw') !== -1
                    ? 'color: #207EFF;'
                    : ''
                "
                >我要取款</span
              >
            </div>
            <div
              class="tabbar-item"
              @click="$router.push('/vipCenter/detailed')"
            >
              <img src="../../assets/img/mingxi.png" alt="" />
              <span
                :style="
                  $route.path == '/vipCenter/detailed' ? 'color: #207EFF;' : ''
                "
                >资金明细</span
              >
            </div>
            <div class="tabbar-item" @click="dialogVisible = true">
              <img src="../../assets/img/hongbao.png" alt="" />
              <span>我的红包</span>
            </div>
            <div
              class="tabbar-item"
              @click="$router.push('/vipCenter/realNameVerification')"
            >
              <img src="../../assets/img/shiming.png" alt="" />
              <span
                :style="
                  $route.path == '/vipCenter/realNameVerification'
                    ? 'color: #207EFF;'
                    : ''
                "
                >实名认证</span
              >
              <div class="auth-icon">
                {{
                  user.auth == 0
                    ? "未认证"
                    : user.auth == 1
                    ? "待认证"
                    : user.auth == 2
                    ? "认证成功"
                    : "认证失败"
                }}
              </div>
            </div>
            <div
              class="tabbar-item"
              @click="$router.push('/vipCenter/friends')"
            >
              <img src="../../assets/img/invite.png" alt="" />
              <span
                :style="
                  $route.path == '/vipCenter/friends' ||
                  $route.path == '/vipCenter/friendsList'
                    ? 'color: #207EFF;'
                    : ''
                "
                >邀请好友</span
              >
            </div>
            <a
              href="https://mp.weixin.qq.com/s/9_e5H32hT1Q2FwEBM1zckg"
              target="_blank"
              class="tabbar-item"
            >
              <img src="../../assets/img/kefu.png" alt="" />
              <span>客服</span>
            </a>
            <div
              class="tabbar-item"
              @click="setMsg();$router.push('/vipCenter/station')"
            >
              <img src="../../assets/img/message.png" alt="" />
              <el-badge is-dot v-if="unread">
                <span
                  :style="
                    $route.path == '/vipCenter/station' ? 'color: #207EFF;' : ''
                  "
                  >站内信</span
                >
              </el-badge>
              <span
                v-else
                :style="
                  $route.path == '/vipCenter/station' ? 'color: #207EFF;' : ''
                "
                >站内信</span
              >
            </div>
          </div>
          <div class="exit">
            <button type="button" @click="quit">退出登录</button>
          </div>
        </div>
      </div>
      <div class="main">
        <router-view></router-view>
      </div>
    </div>
    <!-- 红包 -->
    <el-dialog
      top="30vh"
      title="我的红包"
      :visible.sync="dialogVisible"
      width="300px"
      :show-close="false"
      center
      custom-class="redPacket"
    >
      <div class="redPacket-content">
        <div class="redPacket-item">
          <h4 style="color: #00a15a">{{ user.remaining / 100 }}</h4>
          <span>可用</span>
        </div>
        <div class="redPacket-item">
          <h4>{{ user.used / 100 }}</h4>
          <span>已用</span>
        </div>
        <div class="redPacket-item">
          <h4 style="color: #b00000">{{ user.expire / 100 }}</h4>
          <span>到期</span>
        </div>
      </div>

      <span slot="footer" class="dialog-footer" @click="dialogVisible = false">
        我知道了
      </span>
    </el-dialog>
    <!-- 签到 -->
    <el-dialog
      top="30vh"
      title="签到送积分"
      :visible.sync="signInShow"
      width="290px"
      :show-close="false"
      center
      class="signIn"
    >
      <div class="pop_content">
        <p>
          已获得<span>{{ user.points }}</span
          >积分
        </p>
        <p v-if="flag">
          今日增加<span>{{ dayPoint }}</span
          >积分
        </p>
        <p v-else>当日已签到</p>
        <h5>积分规则：</h5>
        <p class="check_in-desc" v-cloak>
          1-7天连续签到奖励{{ signInPoints[1] }}-{{
            signInPoints[7]
          }}积分，自第8天起每天{{ signInPoints[8] }}积分，中断后从第1天重新计算
        </p>
      </div>
      <span slot="footer" class="dialog-footer" @click="signInShow = false">
        我知道了
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { BASE_URL } from "@constants/constants";
import { getMine, getPic, signIn, getMsg, setMsg } from "@network/mine";
import { getHome } from "@network/home";
export default {
  inject:['reload'],
  name: "",
  data() {
    return {
      setting: {
        title: "",
        keywords: "",
        description: "",
      },
      net: BASE_URL,
      pic: "", //头像
      dialogVisible: false, //红包弹窗
      signInShow: false, //签到
      value2: "",
      user: {},
      flag: true,
      dayPoint: "",
      //积分规则
      signInPoints: {
        1: 1,
        7: 7,
        8: 10,
      },
      unread: false, //是否未读站内信
    };
  },
  created() {
    this.getMine();
    this.getPic();
    this.getHome();
    this.getMsg();
  },

  metaInfo() {
    return {
      title: this.setting.title,
      meta: [
        { name: "keywords", content: this.setting.keywords },
        { name: "description", content: this.setting.description },
      ],
    };
  },

  components: {},

  methods: {
    setMsg() {
      setMsg().then((res) => {
        console.log(res);
        this.reload();
      });
    },
    //是否有未读站内信
    getMsg() {
      getMsg().then((res) => {
        console.log(res);
        if (res.code != 0) {
          return false;
        }
        this.unread = res.data;
      });
    },
    getHome() {
      getHome().then((res) => {
        console.log(res);
        if (res.code != 0) {
          this.$message.error({ message: "获取失败", offset: 300 });
          return false;
        } else {
          this.setting.title = res.data.homePage.website_name;
          this.setting.keywords = res.data.homePage.website_keywords;
          this.setting.description = res.data.homePage.website_desc;
        }
      });
    },
    //退出登录
    quit() {
      this.$storage.remove("token");
      this.$router.replace("/home");
    },
    //获取头像
    getPic() {
      getPic().then((res) => {
        console.log(res);
        if (res.code != 0) {
          // if (res.code == 403) {
          //   this.$storage.remove("token");
          //   this.$message.error("请重新登录");
          //   this.$router.replace("/home");
          //   return false;
          // }
          return false;
        } else {
          this.pic = res.msg.headimg;
        }
      });
    },
    //获取个人信息
    getMine() {
      getMine().then((res) => {
        console.log(res);
        // if (res.code == 403) {
        //   this.$message.error({ message: "请重新登录", offset: 300 });
        //   this.$router.replace("/home");
        //   return false;
        // }
        this.user = res.data;
        if (res.data.auth == 3) {
          // this.showFail = true;
        }
      });
    },
    //签到
    signIn() {
      signIn().then((res) => {
        console.log(res);
        this.signInPoints = res.signInPoints;
        if (res.code != 0) {
          this.flag = false;
          return false;
        } else {
          this.dayPoint = res.points;
          this.getMine();
        }
      });
      this.checkInShow = true;
    },
  },

  computed: {},
};
</script>
<style lang='less' scoped>
@import "../../assets/css/vipCenter/vipCenter.less";
</style>